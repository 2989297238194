import styled from "styled-components";
import { Medium, Small } from "../../../common/elements/Text";

export const Background = styled.div`
  padding: 10.417vw 3.385vw 10.417vw 3.385vw;
  background-color: #f2f2f2;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 25.641vw 4.103vw 25.641vw 4.103vw;
  }
`;

export const PillInside = styled.video`
  width: 81.029vw;
  height: 36.247vw;
  margin-top: 4.479vw;
  mix-blend-mode: darken;
  @media (max-width: 768px) {
    height: 100vw;
    width: 224.615vw;
    transform: rotate(-90deg);
    margin-top: 71.51vw;
    margin-bottom: 71.51vw;
  }
`;

export const FlexParams = styled.div`
  margin-top: 5.743vw;
  height: 15.521vw;
  width: 100%;
  display: flex;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    height: auto;
    margin-top: 0vw;
  }
`;

export const ParamCard = styled.div`
  border-radius: 1.563vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    width: 50%;
    height: 48.462vw;
  }
`;

export const ParamIconContainer = styled.div`
  height: 2.083vw;
  @media (max-width: 768px) {
    height: 6.154vw;
  }
`;

export const ParamIcon = styled.img`
  width: ${(props) => `${props.w}vw`};
  height: ${(props) => `${props.h}vw`};
`;

export const SmallStyled = styled(Small)`
  @media (max-width: 768px) {
    font-size: 3.077vw;
    line-height: 4.308vw;
    letter-spacing: 0.02em;
  }
`;
export const MediumStyled = styled(Medium)`
  @media (max-width: 768px) {
    height: 11.076vw;
    display: flex;
    align-items: center;
    font-size: 4.615vw;
    line-height: 5.538vw;
    letter-spacing: 0.01em;
  }
`;

export const BorderTop = styled.div`
  width: 100%;
  height: 0.781vw;
  border-left: 0.052vw solid #000;
  border-top: 0.052vw solid #000;
  &.last {
    border-right: 0.052vw solid #000;
  }
  @media (max-width: 768px) {
    height: 2.564vw;
    border-left: 0.256vw solid #000;
    border-top: 0.256vw solid #000;
    border-right: 0.256vw solid #000;
    &.last {
      border-right: 0.256vw solid #000;
    }
    &.left {
      border-right: 0;
    }
    &.bottom {
      border-top: 0;
    }
  }
`;

export const BorderBottom = styled.div`
  width: 100%;
  height: 0.781vw;
  border-left: 0.052vw solid #000;
  border-bottom: 0.052vw solid #000;
  &.last {
    border-right: 0.052vw solid #000;
  }

  @media (max-width: 768px) {
    height: 2.564vw;
    border-left: 0.256vw solid #000;
    border-bottom: 0.256vw solid #000;
    border-right: 0.256vw solid #000;
    &.last {
      border-right: 0.256vw solid #000;
    }
    &.left {
      border-right: 0;
    }
    &.bottom {
      border-bottom: 0;
    }
  }
`;
