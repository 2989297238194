import sdk from "../../../../assets/images/drop/sdk.png";
import {
  DropSecondContainer,
  SdkImage,
  SmallB,
  SmallContainer,
  StepD,
  TextContainerStyleD,
  TitleFirst,
  TopContainer,
} from "../DropStyle";

export const DropEight = () => (
  <DropSecondContainer className="orange" id="SDK">
    <TopContainer>
      <SmallB c="#fff">Availability: Q1 2024</SmallB>
      <TextContainerStyleD>
        <TitleFirst c="#fff">
          <StepD c="#fff">⑤</StepD>&nbsp;Pillz SDK
        </TitleFirst>
      </TextContainerStyleD>
      <SmallContainer>
        <SmallB c="rgba(255,255,255,0.5)">
          Build your games, apps and projects on the Pillz device using the
          powerful SDK. Unleash all the features and sensors of the device.
        </SmallB>
      </SmallContainer>
    </TopContainer>
    <SdkImage src={sdk} />
  </DropSecondContainer>
);
