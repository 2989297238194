import left from "../../../../assets/images/drop/left.png";
import right from "../../../../assets/images/drop/right.png";
import {
  DropSecondContainer,
  LeftImage,
  RightImage,
  SmallB,
  SmallContainer,
  StepD,
  TextContainerStyleD,
  TitleFirst,
  TopContainer,
} from "../DropStyle";

export const DropNinth = () => (
  <DropSecondContainer className="black" id="OtherGames">
    <LeftImage src={left} />
    <RightImage src={right} />
    <TopContainer>
      <SmallB c="#fff">From Q3 2023</SmallB>
      <TextContainerStyleD>
        <TitleFirst c="#fff">
          <StepD c="#fff">⑥</StepD>&nbsp;Other games
        </TitleFirst>
      </TextContainerStyleD>
      <SmallContainer>
        <SmallB c="rgba(255,255,255,0.5)">
          Our device is a treasure trove for game development studios. The full
          set of sensors and device capabilities, high performance, unique
          mechanics, combined with our proprietary SDK - anticipate the launch
          of new games with a unique gaming experience.
        </SmallB>
      </SmallContainer>
    </TopContainer>
  </DropSecondContainer>
);
