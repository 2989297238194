import cellz from "../../../../assets/videos/drop/cellz.mp4";
import {
  DeviceFirstVideo,
  DropFourthContainer,
  SmallB,
  SmallContainer,
  SpanStatus,
  StepD,
  TextContainerStyleD,
  TitleFirst,
  TopContainer,
} from "../DropStyle";

export const DropFourth = () => (
  <DropFourthContainer id="CellzNFT">
    <TopContainer>
      <SmallB c="#fff">
        Availability: <SpanStatus c="#26BF3F">right away</SpanStatus>
      </SmallB>
      <TextContainerStyleD>
        <TitleFirst c="#fff">
          <StepD c="#fff">③</StepD>&nbsp;Cellz NFT
        </TitleFirst>
      </TextContainerStyleD>
      <SmallContainer>
        <SmallB c="rgba(255,255,255,0.5)">
          The Cellz NFT is required to create a character in a Cellz game.
          In-game progress is reflected in the appearance of the character and
          his NFT.
        </SmallB>
      </SmallContainer>
    </TopContainer>
    <DeviceFirstVideo className="cellz" muted loop playsInline autoPlay>
      <source src={cellz} type="video/mp4" />
    </DeviceFirstVideo>
  </DropFourthContainer>
);
