import battery from "../../../../assets/images/product/seventh/battery.png";
import batteryW from "../../../../assets/images/product/seventh/battery.webp";
import charge from "../../../../assets/images/product/seventh/charge.png";
import chargeW from "../../../../assets/images/product/seventh/charge.webp";
import gyro from "../../../../assets/images/product/seventh/gyro.png";
import gyroW from "../../../../assets/images/product/seventh/gyro.webp";
import ip67 from "../../../../assets/images/product/seventh/ip67.png";
import ip67W from "../../../../assets/images/product/seventh/ip67.webp";
import nfc from "../../../../assets/images/product/seventh/nfc.png";
import nfcW from "../../../../assets/images/product/seventh/nfc.webp";
import ppi from "../../../../assets/images/product/seventh/ppi.png";
import ppiW from "../../../../assets/images/product/seventh/ppi.webp";
import inside from "../../../../assets/videos/product/seventh/inside.mp4";
import { Image } from "../../../../common/components/Image";
import { useIsAdaptive } from "../../../../hooks/useIsAdaptive";
import {
  BorderBottom,
  BorderTop,
  FlexParams,
  MediumStyled,
  ParamCard,
  ParamIcon,
  ParamIconContainer,
  PillInside,
  SmallStyled,
} from "../../../product/seventh/ProductSeventhStyle";
import {
  DropSecondContainer,
  SmallB,
  SmallContainer,
  StepD,
  TextContainerStyleD,
  TitleFirst,
  TopContainer,
} from "../DropStyle";

export const DropFifth = () => {
  const adpt = useIsAdaptive();

  return (
    <DropSecondContainer className="white" id="PillzDevice">
      <TopContainer>
        <SmallB>Shipping: Q4 2023</SmallB>
        <TextContainerStyleD>
          <TitleFirst c="#000">
            <StepD c="#000">④</StepD>&nbsp;Pillz Device
          </TitleFirst>
        </TextContainerStyleD>
        <SmallContainer>
          <SmallB c="rgba(0,0,0,0.5)">
            Pillz is a device that give you to play NFT-games, merges the real
            world with web3 and allows you to show your NFT.
          </SmallB>
        </SmallContainer>
      </TopContainer>
      <PillInside muted loop playsInline autoPlay>
        <source src={inside} type="video/mp4" />
      </PillInside>
      <FlexParams>
        <ParamCard>
          <BorderTop className="left" />
          <ParamIconContainer>
            <Image webp={ip67W} png={ip67}>
              <ParamIcon
                src={ip67}
                alt="ip67"
                loading="lazy"
                w={adpt !== "mob" ? 2.455 : 7.252}
                h={adpt !== "mob" ? 1.973 : 5.827}
              />
            </Image>
          </ParamIconContainer>
          <MediumStyled c="#000">IP 67</MediumStyled>
          <SmallStyled c="#646464">
            impervious to dust,
            <br />
            maximum water resistance
          </SmallStyled>
          <BorderBottom className="left" />
        </ParamCard>
        <ParamCard>
          <BorderTop />
          <ParamIconContainer>
            <Image webp={nfcW} png={nfc}>
              <ParamIcon
                src={nfc}
                alt="nfc"
                loading="lazy"
                w={adpt !== "mob" ? 2.36 : 6.975}
                h={adpt !== "mob" ? 1.872 : 5.532}
              />
            </Image>
          </ParamIconContainer>
          <MediumStyled c="#000">NFT transfer via NFC</MediumStyled>
          <SmallStyled c="#646464">
            no bluetooth needed,
            <br />
            just touch your phone
          </SmallStyled>
          <BorderBottom />
        </ParamCard>
        <ParamCard>
          <BorderTop className="left bottom" />
          <ParamIconContainer>
            <Image webp={chargeW} png={charge}>
              <ParamIcon
                src={charge}
                alt="charge"
                loading="lazy"
                w={adpt !== "mob" ? 2.43 : 7.178}
                h={adpt !== "mob" ? 1.639 : 4.842}
              />
            </Image>
          </ParamIconContainer>
          <MediumStyled c="#000">
            {adpt !== "mob" ? (
              "Wireless charging"
            ) : (
              <>
                Wireless
                <br /> charging
              </>
            )}
          </MediumStyled>
          <SmallStyled c="#646464">
            charges through
            <br />
            the case without wires
          </SmallStyled>
          <BorderBottom className="left" />
        </ParamCard>
        <ParamCard>
          <BorderTop className="bottom" />
          <ParamIconContainer>
            <Image webp={batteryW} png={battery}>
              <ParamIcon
                src={battery}
                alt="battery"
                loading="lazy"
                w={adpt !== "mob" ? 2.372 : 7.01}
                h={adpt !== "mob" ? 1.168 : 3.45}
              />
            </Image>
          </ParamIconContainer>
          <MediumStyled c="#000">4 days</MediumStyled>
          <SmallStyled c="#646464">
            battery life, two weeks
            <br />
            with charging case
          </SmallStyled>
          <BorderBottom />
        </ParamCard>
        <ParamCard>
          <BorderTop className="left bottom" />
          <ParamIconContainer>
            <Image webp={gyroW} png={gyro}>
              <ParamIcon
                src={gyro}
                alt="gyro"
                loading="lazy"
                w={adpt !== "mob" ? 1.623 : 4.796}
                h={adpt !== "mob" ? 2.035 : 6.013}
              />
            </Image>
          </ParamIconContainer>
          <MediumStyled c="#000">
            Gyro and
            <br /> accelerometer
          </MediumStyled>
          <SmallStyled c="#646464">
            count steps and activity to
            <br />
            control performance of tasks
          </SmallStyled>
          <BorderBottom className="left" />
        </ParamCard>
        <ParamCard>
          <BorderTop className="bottom last" />
          <ParamIconContainer>
            <Image webp={ppiW} png={ppi}>
              <ParamIcon
                src={ppi}
                alt="ppi"
                loading="lazy"
                w={adpt !== "mob" ? 2.074 : 6.127}
                h={adpt !== "mob" ? 1.504 : 4.443}
              />
            </Image>
          </ParamIconContainer>
          <MediumStyled c="#000">326 ppi display</MediumStyled>
          <SmallStyled c="#646464">
            AMOLED technology, 16.7M
            <br /> colours (24-bit), 60Hz
          </SmallStyled>
          <BorderBottom className="last" />
        </ParamCard>
      </FlexParams>
    </DropSecondContainer>
  );
};
