import Vimeo from "@u-wave/react-vimeo";
import {
  RelativeContainer,
  VideoContainer,
} from "../../../product/second/ProductSecondStyle";
import { DropSixthContainer } from "../DropStyle";

export const DropSixth = () => (
  // const adpt = useIsAdaptive();
  // const [isRunning, setIsRunning] = useState(false);
  // const [volume, setVolume] = useState(false);
  // const vidRef = useRef();
  // const isVisible = useOnScreen(vidRef);

  // const videoPreload = () => {
  //   setVolume(false);
  //   setIsRunning(false);
  // };

  // const videoPlay = () => {
  //   if (!isRunning) {
  //     setIsRunning(true);
  //   } else {
  //     setIsRunning(false);
  //   }
  // };

  // useEffect(() => {
  //   if (isVisible) {
  //     if (!volume) {
  //       setVolume(false);
  //     }
  //     setIsRunning(true);
  //   } else {
  //     setIsRunning(false);
  //   }
  // }, [isVisible]);

  <DropSixthContainer>
    <RelativeContainer
      // ref={vidRef}
      className="present"
    >
      {/* <PlayPause onClick={() => videoPlay()} className="present" /> */}
      {/* {volume ? (
          <Volume
            onClick={() => {
              setVolume(false);
            }}
          >
            <VolOn
              width={adpt === "mob" ? "5.692vw" : "2.08vw"}
              height={adpt === "mob" ? "5.692vw" : "2.08vw"}
            />
          </Volume>
        ) : (
          <Volume
            onClick={() => {
              setVolume(true);
            }}
          >
            <VolOff
              width={adpt === "mob" ? "5.692vw" : "2.08vw"}
              height={adpt === "mob" ? "5.692vw" : "2.08vw"}
            />
          </Volume>
        )} */}
      <VideoContainer className="present">
        <Vimeo
          responsive
          video="840238391"
          // paused={!isRunning}
          volume={0.5}
          controls
          playsInline
          // onEnd={() => videoPreload()}
          // loop
        />
      </VideoContainer>
    </RelativeContainer>
  </DropSixthContainer>
);
