import device from "../../../../assets/videos/drop/device.mp4";
import {
  DeviceFirstVideo,
  DropSecondContainer,
  SmallB,
  SmallContainer,
  SpanStatus,
  StepD,
  TextContainerStyleD,
  TitleFirst,
  TopContainer,
} from "../DropStyle";

export const DropSecond = () => (
  <DropSecondContainer id="PillzNFT">
    <TopContainer>
      <SmallB>
        Availability: <SpanStatus c="#26BF3F">right away</SpanStatus>
      </SmallB>
      <TextContainerStyleD>
        <TitleFirst c="#000">
          <StepD c="#000">①</StepD>&nbsp;The Pillz NFT
        </TitleFirst>
      </TextContainerStyleD>
      <SmallContainer>
        <SmallB c="rgba(0,0,0,0.5)">
          Pillz NFT proves ownership of a physical device and is used as an ID
          to log into the entire Pillz ecosystem. Not redeemed Pillz NFTs may be
          sold on OpenSea.
        </SmallB>
      </SmallContainer>
    </TopContainer>
    <DeviceFirstVideo muted loop playsInline autoPlay>
      <source src={device} type="video/mp4" />
    </DeviceFirstVideo>
  </DropSecondContainer>
);
